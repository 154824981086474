import React, {useState} from 'react'
import 'bootstrap-css-only'
import './layout.css'
import './layout_recipes.css'
import Recipes from './recipes'

function App() {
  const [searchFor, setSearchFor] = useState("\"sweet and sour\"");
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <label>Search for Recipe: &nbsp;
          <input type="text" value={searchFor} onChange={e => setSearchFor(e.target.value)} />
          </label>
        </div>
        <div>
          <Recipes search={searchFor}/>
        </div>
      </header>
    </div>
  );
}

export default App;
